import React, { useState, useEffect } from 'react';

export default function Contact() {
  
    //Banner API
    const [slider, setslider] = useState([]);
    useEffect(() => {
      const sliderData = async () => {
        const response = await fetch(
          "https://gazettenotification.co.in/admin/API/bannerAPI.php"
        );
  
        const jsonData = await response.json();
  
        setslider(jsonData);
      };
      sliderData();
    }, []);

     const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(
        "https://gazettenotification.co.in/admin/API/website_settingAPI.php"
      );

      const jsonData = await response.json();

      setwebSetting(jsonData);
    };
    webSettingData();
  }, []);

  
  return (
    <>

 {/* Banner Start */}
 <section>
        <div className="banner">
          <img src={slider.contact_banner} alt={slider.contact_banner_alt} title={slider.contact_banner_title} />
        </div>
      </section>
      {/* Banner End */}

    
  <section className="contact_sc">
  <div className="container">
  <div className="row">
  <div className="col-md-12">
  <div className="service_title text-center">
 <h2>Contact Us</h2>
 <p>Wherever & whenever you need us. We are here for you – contact us for all your support needs.
be it technical, general queries or information support.</p>   
</div>  
  </div>
  </div>

  <div className="row mt-3">

  <div className="col-md-6">
    <div className="form_id">
  <iframe width="100%" height="450" title="Father Name Change" src="https://manage.yourdoorstep.co/forms/ticket" frameBorder="0" allowFullScreen style={{border:'2px solid #03a831'}}></iframe>
  </div> 
  </div>
 
  
<div className="col-md-6">
<div className="video_bx">
<iframe width="100%" height="450" src={`https://www.youtube.com/embed/${webSetting.iframe_video}`} title="Father Name Change Consultant in India | Fatherame Change Agent in India" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen=""></iframe>
</div>
</div>
    

  </div>
  </div>
  </section>

    </>
  )
}
