import React ,{useState,useEffect} from 'react';
import OwlCarousel from 'react-owl-carousel';

export default function About() {
 
//website Setting API
const [webSetting, setwebSetting] = useState([]);
useEffect(() => {
  const webSettingData = async () => {
    const response = await fetch(
      "https://gazettenotification.co.in/admin/API/website_settingAPI.php"
    );

    const jsonData = await response.json();

    setwebSetting(jsonData);
  };
  webSettingData();
}, []);

  //Testimonial API
  const [clientdata, setclientdata] = useState([]);
  useEffect(() => {
    const Allclient = async () => {
      const response = await fetch(
        "https://gazettenotification.co.in/admin/API/clientAPI.php"
      );
      const jsonData = await response.json();
      setclientdata(jsonData);
    };
    Allclient();
  }, []);


    //Banner API
  const [slider, setslider] = useState([]);
  useEffect(() => {
    const sliderData = async () => {
      const response = await fetch(
        "https://gazettenotification.co.in/admin/API/bannerAPI.php"
      );

      const jsonData = await response.json();

      setslider(jsonData);
    };
    sliderData();
  }, []);


const options1 = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  dots: true,
  loop:true,
  autoplay: true,
  navText: ["Prev", "Next"],
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 2,
      },
      800: {
        items: 3,
    },
  },
};

  return (
    <>
{/* Banner Start */}
  <section>
  <div className="banner">
    <img src={slider.about_banner} alt={slider.about_banner_alt} title={slider.about_banner_title} />
  </div>
  </section>  
{/* Banner End */}

  <section className="about_sec">
  <div className="container">
  <div className="row align-items-center">
  <div className="col-md-6">
   <div className="about_tx"> 
  <h4>About Us</h4>
  <h2>{webSetting.about_title}</h2>
  <p>{webSetting.about_tagline}</p>
  <p dangerouslySetInnerHTML={{
                    __html: webSetting.about_desc,
                  }}>
   </p>
  {/* <a href="/">Read More</a> */}
  </div>
  </div>

  <div className="col-md-6">
  <div className="about_img">
  <img src={webSetting.about_img} alt="About" title="About Image" />
  </div>
  </div>

  </div>
  </div>
  </section>

   {/* Testimonials Start */}
   <section className="testimonial_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="about_tx text-center">
                <h4>Testimonials </h4>
                <h2> We love our clients</h2>
              </div>

              <OwlCarousel className='owl-theme' {...options1}>
                {clientdata.map((value) => {
                  return (
                    <>
                      <div className='item client_review'>
                        <img src={value.image} alt="" title="" />
                        <h5>{value.name}</h5>
                        <p className="text-center">{value.designation}</p>
                        <p>{value.description}</p>
                      </div>
                    </>
                  )
                })}

              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonials Start */}

</>
  )
}
