import React ,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function BlogDetails() {

  let {slug} = useParams();
//Blog API
const [blog, setblog] = useState([]);
useEffect(() => {
  const blogData = async () => {
    const response = await fetch(
      "https://gazettenotification.co.in/admin/API/blogDetailsAPI.php?slugname=" + slug
    );

    const jsonData = await response.json();
    console.log(blog.jsonData);
    setblog(jsonData);
  };
  blogData();
});  

  //Testimonial API
  const [clientdata, setclientdata] = useState([]);
  useEffect(() => {
    const Allclient = async () => {
      const response = await fetch(
        "https://gazettenotification.co.in/admin/API/clientAPI.php"
      );
      const jsonData = await response.json();
      setclientdata(jsonData);
    };
    Allclient();
  }, []);


//Blog API
const [homeblog, homesetblog] = useState([]);
useEffect(() => {
  const homeblogData = async () => {
    const response = await fetch(
      "https://gazettenotification.co.in/admin/API/homeblogAPI.php"
    );

    const jsonData = await response.json();

    homesetblog(jsonData);
  };
  homeblogData();
}, []);


//website Setting API
const [webSetting, setwebSetting] = useState([]);
useEffect(() => {
  const webSettingData = async () => {
    const response = await fetch(
      "https://gazettenotification.co.in/admin/API/website_settingAPI.php"
    );

    const jsonData = await response.json();

    setwebSetting(jsonData);
  };
  webSettingData();
}, []);

const showBanner = ()=> {
  let AddData = '';
  if(blog.uploadType === '0' ){
    AddData = <div className="blog_iframe"><iframe width="100%" height="420" src={`https://www.youtube.com/embed/${blog.bannerVideo}`} title="Name Change Service in India | Name Change Agent in India" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe></div>
  }else if(blog.uploadType === '1' ){
    AddData = <img
    src={blog.bannerImage}
    alt={blog.image_alt}
    title={blog.image_title}
    className="bannerimg"
  />
  }else{
    AddData = 
    <div className="banner_desc">
      <div className="BannerDsceBox">
        <h2 dangerouslySetInnerHTML={{
      __html: blog.bannerDesc,
    }}></h2>
      </div>
    </div>
    
  }
  return AddData;
}


const options1 = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  dots: true,
  loop: true,
  autoplay: true,
  navText: ["Prev", "Next"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    800: {
      items: 3,
    },
  },
};


  return (
    <>
    <Helmet>
        <title>{blog.meta_title}</title>
        <meta name="description" content={blog.meta_description} />
        <meta name="keywords" content={blog.meta_keyword} />
        <meta property="og:title" content={blog.meta_title} />
        <meta
          property="og:description"
          content={blog.meta_description}
        />
        <meta
          property="og:image"
          content={blog.bannerImage}
          title={blog.image_title}
        />
        <meta content={blog.meta_keyword} />
        <meta name="Author" content="Name Change Service" />
        <meta content="Global" name="distribution" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:url" content={webSetting.baseURl} />
        <link rel="canonical" href={webSetting.baseURl} />
        <meta property="og:site_name" content={webSetting.baseURl} />
        <link rel="shortlink" href={webSetting.baseURl} />
      </Helmet>
    
    
<section className="blog_detail_sec">
<div className="container">
<div className="row">

<div className="col-md-12">
  
<div className="blog_detail">

<div className="row mt-3">
<div className="col-md-8">
<div className="blog_detail">
{showBanner()} 
</div>
</div>  

<div className="col-md-4">
<div className="form_id">
<iframe width="100%" height="420" title="Father Name Change" src="https://manage.yourdoorstep.co/forms/ticket" frameBorder="0" allowFullScreen style={{border:'2px solid red'}}></iframe>
</div> 
</div>  
</div>

<h3>{blog.title}</h3>
<p>{blog.tagline}</p>  

<div className="row mb-5">

<div className="col-md-6">  
<div className="blog_date">
<span>
<NavLink to="/">
<i className="fa fa-clock-o" aria-hidden="true"></i>
<time>{blog.date} </time>
</NavLink>
</span>

<span>
<NavLink to="/">
<i className="fa fa-commenting-o" aria-hidden="true"></i>
<time>{blog.category}</time>
</NavLink>
</span>
</div>
</div>

<div className="col-md-6">
<div className="blog_social_icon">  
<span><NavLink to="/"><i className="fa fa-facebook" aria-hidden="true"></i></NavLink></span>
<span><NavLink to="/"><i className="fa fa-twitter" aria-hidden="true"></i></NavLink></span> 
<span><NavLink to="/"><i className="fa fa-whatsapp" aria-hidden="true"></i></NavLink></span> 
<span><NavLink to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></NavLink></span>   
</div>
</div>

</div> 


<div dangerouslySetInnerHTML={{
                          __html: blog.description,
                        }}></div>


</div>
</div>

</div>  
</div>  
</section>



      {/*Start How Work */}
      <section className="work_sec">
        <div className="container">
          <div className="row">

            <div className="col-md-12">
              <div className="work_tx">
                <h2>How It Works </h2>
                <p> We provide best name change consultant which provide reliable solution for your name change in your legal document</p>
              </div>
            </div>
          </div>

          <div className="row">

            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-hand-o-up" aria-hidden="true"></i>
                <h6>Select Name Change Consultant</h6>
              </div>
            </div>

            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                <h6>Send Inquiry</h6>
              </div>
            </div>

            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                <h6>Ask Question About Name Change</h6>
              </div>
            </div>

            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-file-text" aria-hidden="true"></i>
                <h6>Send Document For Name correction</h6>
              </div>
            </div>

            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-credit-card" aria-hidden="true"></i>
                <h6>Pay Online</h6>
              </div>
            </div>

            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-hourglass-start" aria-hidden="true"></i>
                <h6>Work Start</h6>
              </div>
            </div>

          </div>
        </div>
      </section>
      {/*End How Work */}

      <div className="container  mt-3  mb-3">
<div className="row">
<div className="col-md-12 col-12">
<div dangerouslySetInnerHTML={{
__html: blog.description2,
}}></div>

</div>
</div>
</div>   

      {/* Testimonials Start */}
      <section className="testimonial_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="about_tx text-center">
                <h4>Testimonials </h4>
                <h2> We love our clients</h2>
              </div>

              <OwlCarousel className='owl-theme' {...options1}>
                {clientdata.map((value) => {
                  return (
                    <>
                      <div className='item client_review'>
                        <img src={value.image} alt="" title="" />
                        <h5>{value.name}</h5>
                        <p className="text-center">{value.designation}</p>
                        <p>{value.description}</p>
                      </div>
                    </>
                  )
                })}

              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonials Start */}

<div className="container mt-3  mb-3">
<div className="row">
<div className="col-md-12 col-12">
<div dangerouslySetInnerHTML={{
__html: blog.description3,
}}></div>

</div>
</div>
</div>                        


      {/* Blog Start */}
      <section className="blog_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="service_title blog_title">
                <h2>Blogs</h2>
              </div>
            </div>
          </div>

          <div className="row">
            {homeblog.map((value) => {
              return (
                <>
                  <div className="col-md-4 col-6">
                    <div className="blog_bx">
                      <NavLink to={`/${value.slug}`} ><img src={value.sort_img} alt={value.image_alt} title={value.image_title} /></NavLink>
                      <div className="blog_tx">
                        <span><i className="fa fa-calendar" aria-hidden="true"></i> {value.date}</span>
                        <h5><NavLink to={`/${value.slug}`}>{value.title}</NavLink></h5>
                        <p className="blog_tx_tg">{value.tagline}</p>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
          </div>

        </div>
      </section>
      {/* Blog End */}

    
    </>
  )
}
