import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

export default function MetaData() {
  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(
        "https://gazettenotification.co.in/admin/API/website_settingAPI.php"
      );
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, []);



  return (
    <>
      <Helmet>
        <title>{webSetting.matatitle}</title>
        <meta name="description" content={webSetting.metadesc} />
        <meta name="Author" content="Name Change Service" />

        <meta content="Global" name="distribution" />

        <meta name="keywords" content={webSetting.metakeywords} />

        <meta property="og:url" content={`${webSetting.baseURl}`} />
        <link rel="canonical" href={webSetting.baseURl} />
        <meta property="og:site_name" content={webSetting.baseURl} />
        <link rel="shortlink" href={webSetting.baseURl} />

        <meta property="og:title" content={webSetting.matatitle} />

        <link rel="icon" type="image/x-icon" href={webSetting.favicon} />

        <meta property="og:type" content="article" />

        <meta property="og:description" content={webSetting.metadesc} />

        <meta content={webSetting.metakeywords} name="classification" />
      </Helmet>
    </>
  );
}
