import React ,{useState,useEffect} from 'react';
import { NavLink } from 'react-router-dom';

export default function Blog() {

    //website Setting API
    const [blog, setblog] = useState([]);
    useEffect(() => {
      const blogData = async () => {
        const response = await fetch(
          "https://gazettenotification.co.in/admin/API/blogAPI.php"
        );
  
        const jsonData = await response.json();
  
        setblog(jsonData);
      };
      blogData();
    }, []);  

        //Banner API
  const [slider, setslider] = useState([]);
  useEffect(() => {
    const sliderData = async () => {
      const response = await fetch(
        "https://gazettenotification.co.in/admin/API/bannerAPI.php"
      );

      const jsonData = await response.json();

      setslider(jsonData);
    };
    sliderData();
  }, []);


  return (
    <>
  
{/* Banner Start */}
<section>
  <div className="banner">
    <img src={slider.blog_banner} alt={slider.blog_banner_alt} title={slider.blog_banner_title} />
  </div>
  </section>  
{/* Banner End */}

<section className="blog_sec">
<div className="container">
<div className="row">
<div className="col-md-12 col-12">
<div className="service_title blog_title">
<h2>Blogs</h2>   
</div>    
</div>
</div>

<div className="row">

{blog.map((value)=>{
  return(
  <>
  <div className="col-md-3 col-6">
<div className="blog_bx">
<NavLink to={`/${value.slug}`} ><img src={value.sort_img} alt={value.image_alt} title={value.image_title} /></NavLink>
<div className="blog_tx">
<span><i className="fa fa-calendar" aria-hidden="true"></i> {value.date}</span>    
<h5><NavLink to={`/${value.slug}`}>{value.title}</NavLink></h5>
<p className="blog_tx_tg">{value.tagline}</p>

</div>
</div>
</div>
  </>
  )
})}
   

{/* <div className="col-md-4 col-6">/
<div className="blog_bx">
<img src="../image/gazette_blog.png" alt="" title="" />
<div className="blog_tx">
<span><i className="fa fa-calendar" aria-hidden="true"></i> 5 January 2024</span>    
<h5><a href="/"
>Lorem ipsum dolor sit amet</a></h5>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

</div>
</div>
</div> 

<div className="col-md-4 col-6">
<div className="blog_bx">
<img src="../image/gazette_blog.png" alt="" title="" />
<div className="blog_tx">
<span><i className="fa fa-calendar" aria-hidden="true"></i> 5 January 2024</span>    
<h5><a href="/"
>Lorem ipsum dolor sit amet</a></h5>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

</div>
</div>
</div>  */}

</div>

</div>    
</section>


</>
  )
}
