import React from 'react'

export default function DataContent({title,desc}) {
  return (
    <>
    <section className="doctx_sec">
<div className="container">
<div className="row">
<div className="col-md-12 col-12">
<div className="doc_tx">
<h4>{title}</h4>
<div
              className="contect_desc"
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            ></div>
</div>
</div>    
</div>
</div>    
</section>
    </>
  )
}
